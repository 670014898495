html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Courier New', Courier, monospace;
}

.hero {
  height: 100vh;
  background-color: #f5ead6;
  padding: 1.5vw 2.22vw;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav>ul {
  display: flex;
  justify-content: right;
  align-items: center;
  list-style: none;
  gap: 2vw;
}

nav>ul>li>a {
  text-decoration: none;
  padding: 0.75vw;
  color: #212429;
  font-weight: 500;
  border: 1px #533327 solid;
  border-radius: 20px;
  cursor: pointer;
}

nav>ul>li>a:hover {
  background-color: #fff;
}

.profile {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10vw;
  margin-top: 5vw;
}

.profile-dt>h1:first-of-type {
  font-size: 2.8vw;
  font-weight: 700;
}

.profile-dt>h1:last-of-type,
.profile-dt>.Typewriter {
  font-size: 2.22vw;
  font-weight: 500;
}

.portrait {
  width: 20vw;
  height: 20vw;
  border-radius: 15px;
}

.portrait>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  border: 5px #fec55c solid;

}

.about-container {
  padding: 2vw 4vw;
  background-color: #fff;
}

.about-container>h3,
.projects>h3,
.skills>h3 {
  text-align: center;
  font-size: 2vw;
  font-weight: 600;
}

.about {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.about>div {
  width: 50%;
}

.card {
  border: 1px #dfdfdf solid;
  border-radius: 5px;
}

.card-header {
  display: flex;
  gap: 1vw;
  padding: 1vw 1.5vw;
  background-color: #f7f7f7;
  border-bottom: 1px #dfdfdf solid;
}

.div1,
.div2,
.div3 {
  width: 1vw;
  height: 1vw;
  border-radius: 1vw;
}

.div1 {
  background-color: #f44336;
}

.div2 {
  background-color: #f4a236;
}

.div3 {
  background-color: #2bc335;
}

.card-body {
  height: auto;
  line-height: 200%;
  font-size: 1.2vw;
  padding: 1.5vw;
}

.download-container {
  text-align: center;
}

.download-container>button {
  background-color: transparent;
  padding: 0.75vw 1vw;
  border-radius: 5px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 1.5vw;
  cursor: pointer;
}

.download-container>button:hover {
  background-color: #212429;
  color: #fff;
}

.projects {
  background-color: #f5ead6;
  padding: 3vw 4vw;
}

.projects-container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1.5vw;
}

.project {
  width: 28vw;
  height: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 1vw;
}

.project>img {
  width: 28vw;
  height: 20vw;
  object-fit: cover;
}

.project>div>p:first-of-type {
  font-size: 1.5vw;
}

.project-icon {
  font-size: 2vw;
  margin-right: 1vw;
}

.skills {
  background-color: #fff;
  padding: 3vw 4vw;
}

.skills-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 3vw;
  justify-content: center;
  align-items: center;
}

.skills-container>div {
  background-color: #f5ead6;
  width: 10vw;
  height: 8vw;
  border-radius: 10px;
  padding: 1vw 0 1vw;
  text-align: center;
}

.hamburger,
.menu {
  display: none;
}

.footer {
  background-color: #212429;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40vh;
}

.footer p {
  font-size: 1.7vw;
}

.socials a {
  color: #fff;
  text-decoration: none;
  font-size: 4vw;
  margin-right: 3vw;
}



@media(max-width: 780px) {
  nav {
    gap: 20vw;
  }

  nav>ul {
    display: none;
  }

  .profile {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20vw;
  }

  .profile-dt>h1:first-of-type {
    font-size: 5.5vw;
  }

  .profile-dt>h1:last-of-type {
    font-size: 4.4vw;
  }

  .portrait {
    width: 70vw;
    height: 50vw;
  }

  .about {
    flex-direction: column;
    margin-bottom: 10vw;
  }

  .about>div {
    width: 100%;
  }

  .card-header {
    display: flex;
    gap: 4vw;
    padding: 4vw 6vw;
  }

  .div1,
  .div2,
  .div3 {
    width: 3vw;
    height: 3vw;
    border-radius: 3vw;
  }

  .card-body {
    line-height: 120%;
    font-size: 4vw;
    padding: 6vw;
  }

  .download-container {
    margin-top: 5vw;
    width: 100%;
  }

  .download-container>button {
    padding: 3vw 5vw;
    font-size: 4.4vw;
    border: 2px #212429 solid;
    color: #212429;
  }

  .projects-container {
    grid-template-columns: auto auto;
    gap: 4vw;
  }

  .project {
    width: 40vw;
    height: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 2vw;
  }

  .project>img {
    width: 40vw;
    height: 35vw;
    object-fit: cover;
  }

  .project-icon {
    font-size: 5vw;
    margin-right: 3vw;
  }

  .project>div>p:first-of-type {
    font-size: 4vw;
  }

  .project>div>p:last-of-type {
    font-size: 3vw;
  }

  .fa-arrow-up-right-from-square {
    font-size: 3vw;
  }

  .skills {
    margin-bottom: 10vw;
  }

  .skills-container {
    grid-template-columns: auto auto;
    gap: 3vw;
  }

  .skills-container>div {
    background-color: #f5ead6;
    width: 25vw;
    height: 20vw;
    padding: 3vw 0 3vw;
  }

  .projects {
    padding-bottom: 10vw;
  }

  .about-container>h3,
  .projects>h3,
  .skills>h3 {
    font-size: 4.4vw;
  }

  .hamburger {
    display: block;
  }

  .menu {
    display: block;
    position: fixed;
    width: 50vw;
    height: 100vh;
    top: 0;
    right: 0;
    background-color: #fff;
    padding: 5vw 4vw;
    z-index: 99999;
  }

  .exit {
    position: relative;
    right: -45vw;
  }

  .menu ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-left: 0;
  }

  .menu ul>li,
  li>a {
    padding: 3vw 3vw;
    width: 100%;
    text-decoration: none;
    color: #212429;
  }

  .menu ul>li:hover  {
    background-color: rgba(100, 100, 100, 0.2);
  }

  .footer p {
    font-size: 3vw;
  }

  .socials a {
    font-size: 6vw;
    margin-right: 5vw;
  }
}